.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #C9F1FF;
  z-index: -1;
  transition: .3s ease-in-out;
  opacity: 0;

  &.active {
    opacity: .8;
    z-index: 9999;
  }
}
.popup-holder {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 100vh;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  z-index: 9999;

  &.active {
    display: flex;
  }

  .rate-card {
    display: none;
    position: relative;
    width: 100%;
    margin: 20px;
    max-width: 750px;
    background: $white;
    padding: 30px 70px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 3px 204px 0 rgba(0,0,0,.2);
    &.fade-in {
      display: block;
      animation: fade-in-up 2s ease-in-out;
    }
    .rating-stars {
      text-align: center;
      margin: 40px 0;
      .star {
        display: inline-block;
        width: 47px;
        height: 44px;
        margin: 0 5px;
        background: url(../img/star.svg) no-repeat center;
        &.active {
          background: url(../img/star-filled.svg) no-repeat center;
        }
      }
    }
    p {
      text-align: center;
      font-size: 24px;
    }
    .share-btn {
      margin: 20px 0;
      background: #3B5998;
      border: 0;
      color: $white;
      font-size: 26px;
      line-height: 34px;
      font-weight: 300;

      &:after {
        display: inline-block;
        content: '';
        position: relative;
        top: 4px;
        width: 26px;
        height: 26px;
        margin-left: 15px;
        background: url(../img/fb.svg) no-repeat center;
        background-size: 100%;
      }
    }
    .close-btn {
      position: absolute;
      width: 50px;
      height: 50px;
      background: $white url(../img/close-btn.svg) no-repeat center top;
      border-radius: 100%;
      border: 0;
      box-shadow: 0 2px 20px 0 rgba(0,0,0,.2);
      top: -20px;
      right: -15px;
      outline: 0;
    }
  }
}
@keyframes fade-in-up {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
}
@media screen and (max-width: 767px){
  .play-screen {
    .close-btn {
      top: 15px;
      left: 15px;
      width: 30px;
      height: 30px;
    }
    .current-set {
      width: 165px;
      height: 165px;
      .inner {
        position: absolute;
        top: 15px;
        right: 15px;
        left: 15px;
        bottom: 15px;
        .play-btn, .pause-btn {
          width: 40px;
          height: 40px;
          background-size: cover;
        }
      }
      &:before {
        width: 110px;
        height: 65px;
      }
      &-group {
        width: 80px;
        height: 50px;
        p {
          font-size: 16px;
          span {
            font-size: 18px;
          }
        }
      }
    }
    .duration {
      font-size: 20px;
    }
  }
  .popup-holder {
    .rate-card {
      padding: 30px;
      .rating-stars {
        .star {
          display: inline-block;
          width: 31px;
          height: 26px;
          background-size: cover;
          &.active {
            background-size: cover;
          }
        }
      }
      p {
        font-size: 20px;
      }
    }
  }
}

@keyframes fade-in {
  0% {
    transform: translateY(-100%);
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}