.push-menu {
	position: fixed;
	z-index: 200;
	background-color: $color-yellow;
	transition: 0.3s linear;
	width: 100%;
	height: 100%;
	padding-top: 75px;
	overflow-y: scroll;
	top: 0;
	right: 0;
	transform: translateX(100%);
	font-family: 'Rubik', sans-serif;
	font-weight: 700;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			display: block;
			text-align: center;
			border-bottom: solid 1px #d3c785;
			a {
				display: block;
				padding: 12px 24px;
				color: $text-color;
				font-size: 18px;
				text-transform: uppercase;
				text-decoration: none;
			}
		}
	}
	&-mask {
		position: fixed;
		z-index: 100;
		top: 0;
		left: 0;
		overflow: hidden;
		width: 0;
		height: 0;
		background-color: #000;
		opacity: 0;
		transition: opacity 0.3s, width 0s 0.3s, height 0s 0.3s;
		&.is-active {
			width: 100%;
			height: 100%;
			opacity: 0.7;
			transition: opacity 0.3s;
		}
	}
	.hamburger {
		outline: none;
		&-inner {
			&, &:before, &:after{
				background: $text-color;
			}
		}
	}

	.profile-img {
		display: none;
	}
}
body.disable-scroll {
	overflow: hidden;
}
.main-wrapper {
	transition: .3s linear;
	&.has-push-right {
	  	transform: translateX(-100%);
	}
}
@media all {
  	.push-menu {
    	width: 300px;
    	transform: translateX(300px);
    	&.is-active {
  			transform: translateX(0);
		}
  	}
  	.main-wrapper {
  		&.has-push-right {
  		  transform: translateX(-300px);
  		}
  	}
}

@media all and (max-width: 300px) {
	.push-menu {
		width: 100%;
	}
}

@media screen and (max-width: 1200px) {
	.push-menu {
		padding-top: 20px;
	}
}