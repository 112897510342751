.top-banner {
	position: relative;
	padding: 40px 50px;
	box-shadow: 0 10px 25px 0 rgba(0,0,0,.1);
	margin-bottom: 20px;
	.banner-img {
		position: absolute;
		bottom: 0;
		right: 0;
	}
	.new-label {
		width: 112px;
		height: 69px;
		background: url(../img/cloud2.svg) no-repeat center;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 30px;
	}
	h2 {
		font-size: 40px;
	}
	p {
		font-size: 18px;
		font-family: 'IntroHeadRBase', script;
	}
}

.packages-wrapper {
	padding-top: 40px;
	.packages {
		padding: 0;
		margin-bottom: 30px;
	}
}

@media screen and ( max-width: 992px ) {
	.meditations-page .side-menu {
		display: none;
	}
}

@media screen and (max-width: 767px) {
	.top-banner {
		padding: 15px 20px;
		.banner-img {
			width: 75px;
		}
		.new-label {
			width: 72px;
			height: 45px;
			font-size: 18px;
		}
		h2 {
			font-size: 25px;
		}
		p {
			font-size: 18px;
		}
	}
}