$blurStart: 0px;
$blurEnd: 3px;
$topEnd: 0;
$topStart: 0px;
$opacityStart: 1;
$opacityEnd: 0;

body{
  transition: 0.2s;
  transition-timing-function: ease;
}

.transition-wrapper {
  position: relative;
  z-index: 1;

  .transition-page {
    transition: 0.2s;
    transition-timing-function: ease;

    opacity: $opacityStart;
    position: relative;
    top: $topStart;
    filter:blur($blurStart);
  }

  .footer {
    transition: 0.2s;
    transition-timing-function: ease;
  }

  .transition-leave,
  .transition-appear,
  .transition-leave-active,
  .transition-appear-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .transition-appear {
    .transition-page, .footer {
      transition-delay: 0.2s;
      //top: $topEnd;
      filter:blur($blurEnd);
    }

    &.transition-appear-active .transition-page, .footer {
      opacity: $opacityStart;
      //top: $topStart;
      filter:blur($blurStart);
    }

    z-index: 10;
  }

  .transition-leave {
    .transition-page, .footer {
      //top: $topStart;
      filter:blur($blurStart);
    }

    &.transition-leave-active .transition-page, .footer {
      opacity: $opacityEnd;
      //top: $topEnd;
      filter:blur($blurEnd);
    }

    z-index: 20;
  }
}