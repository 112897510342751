.top-section {
	//background: #BBEDFF;
	p {
		font-size: 32px;
	}
	.top-img-holder {
		margin: 150px 0 50px;
		position: relative;
		.bg-circle {
			width: 660px;
			height: 660px;
			background: $color-yellow;
			position: absolute;
			z-index: 0;
			border-radius: 100%;
			left: 50%;
			transform:translateX(-50%);
			top: -80px;
		}
		img {
			position: relative;
			z-index: 10;
		}
		.subscribe-holder,
		.register-holder {
			position: absolute;
			top: 0;
			z-index: 20;
			.cloud-text {
				position: relative;
				width: 270px;
				height: 165px;
				background: url(../img/cloud2.svg) no-repeat;
				background-size: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 25px;
				text-transform: uppercase;
				.btn-red {
					position: absolute;
					bottom: 0;
				}
			}
		}
		.subscribe-holder {
			left: 0;
			.cloud-text {
				.btn-red {
					left: 0;
				}
			}
		}
		.register-holder {
			right: 8%;
			top: -50px;
			.cloud-text {
				.btn-red {
					right: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 992px) {
	.top-section {
		.top-img-holder {
			.subscribe-holder,
			.register-holder {
				top: -170px;
			}
			.register-holder {
				right: 0;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.top-section {
		p {
			font-size: 25px;
		}
		.top-img-holder {
			margin: 150px 0 50px;
			position: relative;
			.bg-circle {
				width: 460px;
				height: 460px;
			}
			.subscribe-holder,
			.register-holder {
				top: -140px;
				.cloud-text {
					width: 215px;
					height: 145px;
					font-size: 20px;
				}
			}
			.subscribe-holder {
				top: -140px;
			}
		}
	}
}

@media screen and (max-width: 520px) {
	.top-section {
		.top-img-holder {
			margin: 270px 0 50px;

			.bg-circle {
				width: 320px;
				height: 320px;
			}
			.subscribe-holder,
			.register-holder {
				top: -250px;
				.cloud-text {
					width: 195px;
					height: 133px;
					font-size: 18px;
				}
			}
			.register-holder {
				top: -130px;
			}
		}
	}
}

@media screen and (max-width: 320px) {
	.top-section {
		.top-img-holder {
			.bg-circle {
				width: 200px;
				height: 200px;
			}
		}
	}
}

.about-section {
	background: $color-green;
	color: $white;
	position: relative;
	margin-top: -170px;
	padding: 120px 0 0;
	background: url(../img/green-arc.svg) no-repeat center top;
	background-size: cover;
	text-align: center;
	ul {
		padding: 50px 0;
		display: inline-block;
		text-align: left;
		li {
			font-size: 26px;
			line-height: 80px;
			list-style: none;
			&:before {
				display: inline-block;
				content: '';
				width: 25px;
				height: 21px;
				background: url(../img/check.svg) no-repeat center;
				margin-right: 30px;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.about-section {
		margin-top: -100px;
		padding: 60px 0 0;
		ul {
		    padding: 30px 0;
		    li {
		        font-size: 20px;
		        padding: 15px 0;
		        line-height: 25px;
		    }
		}
	}
}

.stress-relief {
	padding: 100px 0;
	background: $white;
	color: $color-green;
	p {
		font-size: 25px;
	}
}
@media screen and (max-width: 767px) {
	.stress-relief {
		padding: 50px 0;
		p {
			font-size: 20px;
		}
	}
}

.losing-focus {
	padding: 100px 0;
	background: #FFBDA6 url(../img/hearts-pattern.svg);
	color: #AC4F37;
	text-align: center;
	p {
		font-size: 25px;
	}
}
@media screen and (max-width: 767px) {
	.losing-focus {
		padding: 50px 0;
		p {
			font-size: 20px;
		}
	}
}

.test-medita {
	//background: $white;
	color: $text-color;
	padding: 100px 0 0;
	p {
		font-size: 25px;
	}
	.medita-app {
		margin-top: -130px;
	}
}

@media screen and (max-width: 1170px) {
	.test-medita {
		.medita-app {
			margin: 0 auto;
			max-width: 300px;
		}
		text-align: center;
	}
}

@media screen and (max-width: 767px) {
	.test-medita {
		padding: 50px 0;
		p {
			font-size: 20px;
		}
		.medita-app {
			margin: 0 auto;
			max-width: 200px;
		}
	}
}
