.header {
	padding: 50px 0;
	.logo {
		width: 90px;
	}
	.logo-beta {
		width: 120px;
	}
	.menu-holder {
		display: flex;
		justify-content: flex-end;
		.main-menu {
			display: inline-block;
			ul {
				padding: 0;
				margin-bottom: 0;
				li {
					display: inline-block;
					list-style: none;
					font-size: 18px;
					line-height: 60px;
					text-transform: uppercase;
					font-family: 'Rubik';
					font-weight: 700;
					position: relative;
					a {
						color: inherit;
						text-decoration: none;
						transition: .3s ease;
						padding: 10px 15px 8px;

						&.active {
							color: $link-active;
						}
					}
					&.active,
					&:hover,
					&:active,
					&:focus {
						a {
							color: $link-active;
						}
					}
					&.not-loggedin {
						a {
							border: 3px solid $text-color;
							border-radius: 30px;
							&:hover {
								border: 3px solid $link-active;
							}
						}
					}
				}
			}
		}
		.profile-img {
			display: inline-block;
			width: 45px;
			height: 45px;
			border-radius: 100%;
			margin: 7px 0 7px 10px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.hamburger {
			display: none;
			outline: 0;
			&-inner {
				&:before,
				&:after {
					left: -1px;
				}
				&,
				&:before,
				&:after {
					background: $text-color;
					border: 1px solid $text-color;
				}
			}
		}
	}
}

/*
.not-logged-in {
	.header {
		background: #BBEDFF;
	}
}
*/

@media screen and ( max-width: 992px ) {
	.header {
		padding: 25px 0;
		.logo {
			width: 50px;
		}
		.menu-holder {
			.main-menu {
				display: none;
			}
			.profile-img {
				margin: 0;
			}
			.hamburger {
				display: inline-block;
				padding: 0 0 0 20px;
			}
		}
	}
}
