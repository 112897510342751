.packages {
	display: flex;
	flex-wrap: wrap;
   	justify-content: flex-start;
   	padding: 35px 0;
   	position: relative;
   	min-height: 100px;
	.package {
		position: relative;
		// width: 205px;
		height: 255px;
		padding: 20px;
		border-radius: 5px;
		box-shadow: 0 10px 25px 0 rgba(0,0,0,.1);
		margin: 10px;
		margin-bottom: 20px;
		flex: 0 1 205px;
		color: inherit;
		display: block;

		&:hover {
			text-decoration: none;
		}
		h4 {
			font-size: 22px;
			font-family: 'IntroHeadRBase';
		}
		p {
			font-family: 'IntroHeadRBase', script;
			font-size: 18px;
		}
		.progress {
			position: absolute;
			bottom: 15px;
			left: 20px;
			font-size: 16px;
			span {
				font-size: 20px;
			}
		}
	}
	&.main {
		.package {
			&:nth-child(n) {
				background: url(../img/my-package1.svg) no-repeat center;
				background-size: cover;
				&.done {
					background: url(../img/my-package1-done.svg) no-repeat center;
					background-size: cover;
				}
			}
			&:nth-child(2n) {
				background: url(../img/my-package2.svg) no-repeat center;
				background-size: cover;
				&.done {
					background: url(../img/my-package2-done.svg) no-repeat center;
					background-size: cover;
				}
			}
			&:nth-child(3n) {
				background: url(../img/my-package3.svg) no-repeat center;
				background-size: cover;
				&.done {
					background: url(../img/my-package3-done.svg) no-repeat center;
					background-size: cover;
				}
			}
		}
	}
	&.onetime {
		.package {
			display: flex;
			align-items: center;
			justify-content: center;
			h4 {
				font-size: 22px;
			}
			&:nth-child(n) {
				background: url(../img/cooking.svg) no-repeat center;
				background-size: cover;
				h4 {
					color: #C0B162;
				}
			}
			&:nth-child(2n) {
				background: url(../img/holiday.svg) no-repeat center;
				background-size: cover;
				h4 {
					color: #CB4847;
				}
			}
			&:nth-child(3n) {
				background: url(../img/running.svg) no-repeat center;
				background-size: cover;
				h4 {
					color: #242A77;
				}
			}
		}
	}
	&.happiness {
		.package {
			background: url(../img/love-bg.svg) no-repeat center;
			background-size: cover;
			&.done {
				background: url(../img/love-bg-done.svg) no-repeat center;
				background-size: cover;
			}
		}
	}
	&.work {
		.package {
			background: url(../img/work.svg) no-repeat center;
			background-size: cover;
			&.done {
				background: url(../img/work-done.svg) no-repeat center;
				background-size: cover;
			}
		}
	}
	&.sport {
		.package {
			&:nth-child(n) {
				background: url(../img/sport1.svg) no-repeat center;
				background-size: cover;
				&.done {
					background: url(../img/sport1-done.svg) no-repeat center;
					background-size: cover;
				}
			}
			&:nth-child(2n) {
				background: url(../img/sport2.svg) no-repeat center;
				background-size: cover;
				&.done {
					background: url(../img/sport2-done.svg) no-repeat center;
					background-size: cover;
				}
			}
			&:nth-child(3n) {
				background: url(../img/sport3.svg) no-repeat center;
				background-size: cover;
				&.done {
					background: url(../img/sport3-done.svg) no-repeat center;
					background-size: cover;
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.packages {
		justify-content: center;
	}
}
