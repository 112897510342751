// Section with current meditation set
.section-current-set {
	.current-set {
		position: relative;
		box-sizing: border-box;
		width: 265px;
		height: 265px;
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		background: $color-yellow;
		border-radius: 100%;
		margin: 0 auto;
		border: 7px solid rgba(252,237,154,.48);
		box-shadow: 0 2px 25px 1px rgba(252,237,154,.6);
		transition: .3s ease;
		animation: shine 2s ease-in-out infinite;
		h2 {
		    font-size: 30px;
		    line-height: 35px;
		    margin-bottom: 20px;
		    font-family: 'IntroHeadRBase';
		}
		.play-btn {
			border: 1px solid $text-color;
			border-radius: 30px;
			padding: 9px 25px 6px;
			padding-right: 60px;
			font-size: 20px;
			font-weight: 300;
			line-height: 30px;
			text-transform: uppercase;
			outline: none;
			background: url(../img/play-btn.svg) no-repeat 85% center;
			color: inherit;

			&:hover {
				text-decoration: none;
			}
		}
		.play-btn-mobile {
			display: none;
			border: 0;
			outline: none;
			background: none;
			padding: 0;
			width: 40px;
			height: 40px;
			img {
				width: 100%;
				height: 100%;
			}
		}
		&:before {
			display: block;
			content: '';
			width: 190px;
			height: 115px;
			background: url(../img/blurred-cloud.svg) no-repeat center;
			background-size: cover;
			position: absolute;
			left: 80%;
			bottom: 20px;
			z-index: -1;
			transition: .3s ease;
			animation: swing 4s ease-in-out infinite;
		}
		&-group {
			display: flex;
		    justify-content: center;
		    align-items: center;
			width: 155px;
			height: 95px;
			background: url(../img/cloud2.svg) no-repeat center;
			background-size: cover;
			position: absolute;
			right: 90%;
			bottom: 20px;
			transition: .3s ease;
			animation: swing 4s ease-in-out 2s infinite;
			p {
				margin: 0;
				font-size: 20px;
				span {
					font-size: 22px;
				}
			}
		}
	}
}
@keyframes shine {
	0% {
		box-shadow: 0 2px 25px 1px rgba(252,237,154,.6);
	}
	50% {
		box-shadow: 0 2px 25px 21px rgba(252,237,154,.6);
	}
	100% {
		box-shadow: 0 2px 25px 1px rgba(252,237,154,.6);
	}
}

@keyframes swing {
	0% {
		transform: translatex(0);
	}
	50% {
		transform: translatex(-15px);
	}
	100% {
		transform: translatex(0);
	}
}

@media screen and (max-width: 767px) {
	.section-current-set {
		.current-set {
			width: 180px;
			height: 180px;
			h2 {
				margin: 0;
				font-size: 22px;
			}
			.play-btn {
				display: none;
			}
			.play-btn-mobile {
				display: block;
			}
			&:before {
				width: 130px;
				height: 75px;
				bottom: 10px;
				background: url(../img/cloud2.svg) no-repeat center;
				background-size: cover;
			}
			&-group {
				width: 105px;
				height: 65px;
				p {
					font-size: 16px;
					span {
						font-size: 18px;
					}
				}
			}
		}
	}
}



// Section with Daily Exercises
.section-daily-exercises {
	padding: 100px 0 70px;
	h2 {
		text-align: center;
	}
	p {
		font-family: 'IntroHeadRBase', script;
		font-size: 24px;
		line-height: 32px;
		text-align: center;
	}
	.steps {
		display: flex;
		justify-content: space-around;
		align-items: center;
		padding: 60px 0 20px;
		.step {
			text-align: center;
			transition: .4s ease-in-out;
			&-icon {
				width: 145px;
				height: 145px;
				margin: 0 auto 35px;
				background: $white;
				border-radius: 100%;
				box-shadow: 0 10px 25px 0 rgba(0,0,0,.2);
				transition: .3s ease-in-out;
			}
			&.one .step-icon {
				background: $white url(../img/relax.svg) no-repeat center;
			}
			&.two .step-icon {
				background: $white url(../img/focus.svg) no-repeat center;
			}
			&.three .step-icon {
				background: $white url(../img/breathe.svg) no-repeat center;
			}
			h3 {
		    	font-family: 'IntroHeadRBase';
				font-size: 25px;
				font-weight: 300;
			}
			&.fade-in{
				animation: fade-in 2s ease-in-out;
			}
		}
	}
}
@keyframes fade-in {
	0% {
		transform: translateY(50%);
		opacity: 0;
	}
	50% {
		transform: translateY(0);
		opacity: 1;
	}
}
@media screen and (max-width: 767px) {
	.section-daily-exercises {
		padding: 50px 0 30px;
		p {
			font-size: 20px;
		}
		.steps {
			padding: 40px 0 0;
			.step {
				&-icon {
					width: 125px;
					height: 125px;
				}
				h3 {
					font-size: 25px;
					line-height: 35px;
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.section-daily-exercises {
		padding: 30px 0 15px;
		.steps {
			padding: 20px 0 0;
			.step {
				&-icon {
					width: 100px;
					height: 100px;
				}
				&.one,
				&.two,
				&.three {
					.step-icon {
						background-size: 50% 50%;
					}
				}
				h3 {
					font-size: 25px;
					line-height: 35px;
				}
			}
		}
	}
}

//Section My Packages
.section-my-packages {
	padding: 30px 0;
	h2 {
		text-align: center;
	}
	.packages {
		justify-content: center;
	}

}

.section-onetime-sessions {
	padding: 30px 0;
	h2 {
		text-align: center;
	}
	.packages {
		justify-content: center;
	}

}

.meditating-girl {
	margin: 20px auto;
}