.side-image {
  text-align: center;

  img {
    width: 250px;
    max-width: 250px;
    border-radius: 125px;
  }
}

.team-member {
  margin-top: 50px;

  .title {
    margin-top: 0;
  }
}