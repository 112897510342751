html, body {
	min-height: 100vh;
}

body {
    background: url(../img/blue-bg.svg) #c7f1ff repeat-x;
    font-family: 'IntroHeadRBase', sans-serif;
	text-align: justify;
    color: $text-color;
	font-size: 22px;
   	//&.not-logged-in {
   	//	background: #BBEDFF;
   	//}
}
.main-wrapper {
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	padding-bottom: 420px;
	//.not-logged-in & {
	//	padding-bottom: 385px;
	//}
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 900;
	font-family: 'Rubik', sans-serif;
	text-transform: uppercase;
}
h1 {
	font-size: 45px;
	line-height: 55px;
}
h2 {
	font-size: 32px;
	line-height: 42px;
}

h4 {
	line-height: 42px;
	margin-top: 20px;
}

section {
	position: relative;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

a{
	cursor: pointer;
}

.btn {
	&-default {
		background: none;
		border: 3px solid $text-color;
		font-size: 18px;
		min-width: 175px;
		padding: 15px 30px 13px;
		border-radius: 30px;
		font-family: 'Rubik';
		font-weight: 700;
		margin-top: 40px;
		text-transform: uppercase;
		outline: 0;

		transition: all .2s ease-in-out;
		&:hover,
		&:active,
		&:focus,
		&:active:focus,
		&:active:hover {
			background: $text-color;
			outline: 0;
			border: 3px solid $text-color;
			color: $white;

			&.green {
				background: $color-green;
				color: $white;
			}
			&.pink {
				background: $color-pink;
				color: $white;
			}
		}
		&.green {
			color: $color-green;
			border: 3px solid $color-green;
		}
		&.pink {
			color: $color-pink;
			border:  3px solid $color-pink;
		}
	}
	&-red {
		background: linear-gradient(#D64343,#DC2D37);
		border-radius: 30px;
		color: $white;
		padding: 13px 17px 10px;
		font-size: 20px;
		&:hover,
		&:active,
		&:focus,
		&:active:focus,
		&:active:hover {
			color: $white;
			background: linear-gradient(#DC2D37,#D64343);
		}
	}
}
.main-content {
	padding-bottom: 50px;
}

.loading {
	visibility: hidden;
}

.arrow {
	border: 0;
	width: 50px;
	height: 50px;
	background: url(../img/arrow.svg) no-repeat center;

	&.arrow-left {
		transform:scaleX(-1);
	}

	&:focus {
		outline: none;
	}
}

.sidebar-row {
	display: flex;
}

.sticky-container {
	height: 100%;
}

.separator {
	border-top: 2px solid $text-color;
	margin: 40px 0;
}

@media screen and ( max-width: 992px ) {
	h1 {
		font-size: 35px;
		line-height: 45px;
	}
	h2 {
		font-size: 25px;
		line-height: 35px;
	}
	.main-wrapper {
		&, .not-logged-in & {
			padding-bottom: 0;
		}
	}
	.btn {
		&-default {
			font-size: 20px;
		}
	}
	.main-content {
		padding-bottom: 20px;
	}

	.sidebar-row {
		display: block;
	}
}

@media screen and ( max-width: 768px ) {
	h1 {
		font-size: 25px;
		line-height: 35px;
	}
	h2 {
		font-size: 20px;
		line-height: 30px;
	}
}