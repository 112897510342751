.side-menu {
	&.mobile-side-menu {
		display: none;
	}
	ul {
		padding: 0;
		li {
			position: relative;
			list-style: none;
			font-size: 18px;
			font-family: 'Rubik';
			font-weight: 700;
			line-height: 45px;
			text-transform: uppercase;
			padding-left: 30px;
			transition: .3s ease-in-out;
			a {
				color: inherit;
				text-decoration: none;

				&.active {
					color: $link-active;
				}
			}
			&.has-submenu {
				&:before {
					display: inline-block;
					content: '\003E';
					position: absolute;
					left: 10px;
					top: -3px;
				}
			}
			.sub-menu {
				display: none;
				li {
					font-size: 20px;
				}
			}
			&.active {
				color: $link-active;
				.sub-menu {
					display: block;
					color: $text-color;
				}
				&.has-submenu:before {
					transform: rotate(90deg);
				}
			}
			&:hover {
				color: $link-active;
				.sub-menu {
					color: $text-color;
				}
			}
		}
	}
}
@media screen and ( max-width: 992px ) {
	.side-menu {
		&.mobile-side-menu {
			display: block;
		}

		ul {
			li {
				display: inline-block;
				padding: 0;
				font-size: 20px;
				margin-right: 25px;
				&, &.active {
					.sub-menu {
						display: none;
					}
				}
				&.has-submenu {
					&:before {
						display: none;
					}
				}
			}
		}
	}
}
