.cookie-disclaimer {
	background: $color-yellow;
	padding: 15px 0;
	font-size: 18px;
	.container {
		display: flex;
		align-items: center;
		.msg-box {
			margin-right: 20px;
			a {
				color: $color-green;
				&:hover {
					text-decoration: none;
				}
			}
		}
		.close {
			display: flex;
			margin: 0 0 0 auto;
			justify-content: center;
			font-size: 18px;
			min-width: 1px;
			padding: 5px 25px;
			border: 1px solid $text-color;
			font-family: 'IntroHeadRBase';
		}
	}
}
